import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '@/components/v2/Button.jsx';
import Hexagon from '@/components/v2/Hexagon.jsx';
import asset from '@/utils/VaporUtil.js';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrow-right.svg';
import { Link } from '@inertiajs/react';

const slides = [
    {
        quote: '"Investeer met vertrouwen, creëer een zekere toekomst"',
        image: asset('assets/images/testimonials/slide-01.jpg'),
    },
    {
        quote: '"Met zekerheid als basis, bouwen we aan een solide toekomst."',
        image: asset('assets/images/testimonials/slide-02.jpg'),
    },
    {
        quote: '"Stabiele investeringen voor duurzame groei en succes."',
        image: asset('assets/images/testimonials/slide-03.jpg'),
    },
    {
        quote: '"Investeer voor stabiliteit en duurzame vooruitgang."',
        image: asset('assets/images/testimonials/slide-04.jpg'),
    },
];

const SLIDE_DURATION = 5000;

const motions = {
    fade: {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    },
    container: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.5,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                staggerChildren: 0.05,
                staggerDirection: -1,
            },
        },
    },
    text: {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.25, ease: 'easeOut' } },
        exit: { opacity: 0, y: 20, transition: { duration: 0.25, ease: 'easeIn' } },
    },
    page: {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { type: 'tween', ease: 'anticipate', duration: 0.25 } },
        exit: { opacity: 0, transition: { type: 'tween', ease: 'anticipate', duration: 0.25 } },
    },
};

const Guestlayout = ({ children }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const currentRoute = route().current();
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, SLIDE_DURATION);

        return () => clearTimeout(timer);
    }, [currentSlide]);

    return (
        <div className='flex min-h-screen bg-pure-white font-poppins text-deep-black'>
            <div className='sticky top-10 z-20 my-10 ml-10 hidden h-[calc(100vh-5rem)] w-full overflow-hidden rounded-2xl bg-[black] xl:block'>
                <div className='absolute inset-0'>
                    {slides.map((slide, index) => (
                        <motion.div
                            key={index}
                            className='absolute inset-0 bg-cover bg-center'
                            style={{ backgroundImage: `url(${slide.image})` }}
                            initial={{ opacity: 0, scale: 1 }}
                            animate={{
                                opacity: index === currentSlide ? 1 : 0,
                                scale: index === currentSlide ? 1.05 : 1,
                            }}
                            transition={{
                                opacity: { duration: 1 },
                                scale: { duration: SLIDE_DURATION / 1000, ease: 'linear' },
                            }}
                        />
                    ))}
                    <div className='absolute inset-0 bg-gradient-to-b from-[black]/0 to-[black]/75' />
                </div>

                <AnimatePresence mode='wait'>
                    <motion.div key={currentSlide} className='absolute inset-0 flex flex-col justify-end' variants={motions.fade} initial='hidden' animate='visible' exit='hidden' transition={{ duration: 0.25 }}>
                        <motion.div className='relative z-10 flex w-full max-w-screen-lg flex-col gap-10 p-10 pb-40' variants={motions.container} initial='hidden' animate='visible' exit='exit'>
                            <motion.h1 className='text-6xl font-semibold text-pure-white' variants={motions.text}>
                                {slides[currentSlide].quote}
                            </motion.h1>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>

                <div className='absolute bottom-0 left-0 z-20 flex w-full items-center justify-between p-10'>
                    <div className='flex items-center gap-2'>
                        {slides.map((_, index) => (
                            <div key={index} className='h-2 w-12 cursor-pointer overflow-hidden rounded-lg bg-pure-white bg-opacity-30' onClick={() => setCurrentSlide(index)}>
                                <motion.div
                                    className='h-full rounded-lg bg-pure-white'
                                    initial={{ width: '0%' }}
                                    animate={{
                                        width: currentSlide === index ? '100%' : '0%',
                                    }}
                                    transition={{
                                        duration: currentSlide === index ? SLIDE_DURATION / 1000 : 0,
                                        ease: 'linear',
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <a href={'https://www.capilex.com'} rel='noreferrer'>
                        <Button className={'h-12 bg-pure-white/20 px-6 text-pure-white backdrop-blur-md hover:bg-pure-white hover:text-deep-black'}>
                            <span>Terug naar website</span>
                            <ArrowRightIcon className={'size-6'} />
                        </Button>
                    </a>
                </div>
            </div>

            <div className='relative mx-auto flex w-full max-w-xl items-center justify-center overflow-y-clip'>
                <div className='relative z-30 mx-auto flex min-h-screen flex-col items-start justify-between gap-10 p-5 xl:p-20'>
                    <Link href={route('login')}>
                        <img className='h-10' src={asset('assets/images/logo/black.svg')} alt='Capilex logo' />
                    </Link>

                    <AnimatePresence mode='wait'>
                        <motion.main key={currentRoute} className={'flex grow flex-col'} variants={motions.page} initial='hidden' animate='visible' exit='exit'>
                            {children}
                        </motion.main>
                    </AnimatePresence>

                    <div className={'flex w-full justify-end'}>
                        <a href={'https://www.capilex.com/contact'} target={'_blank'} rel='noreferrer'>
                            <p className='w-fit underline'>Hulp nodig?</p>
                        </a>
                    </div>
                </div>

                <Hexagon
                    className='absolute right-[-15vh] top-[-10vh] size-[40vh] rotate-180 text-steel-blue opacity-35'
                    animate={{
                        scale: [1, 1.4, 1],
                        rotate: [40, 80, 40],
                    }}
                    transition={{ duration: 25, repeat: Infinity }}
                />

                <Hexagon
                    className='absolute bottom-[-10vh] left-[-35vh] h-[50vh] text-steel-blue opacity-35'
                    animate={{
                        scale: [1, 1.25, 1],
                        rotate: [140, 100, 140],
                    }}
                    transition={{ duration: 25, repeat: Infinity }}
                />
            </div>
        </div>
    );
};

export default Guestlayout;
